//
// Activated webfonts
// --------------------------------
// fonts utilization after asynchronously loaded
// used package: https://github.com/typekit/webfontloader

// class set in html tag after fonts have been loaded
.wf-active {

  //
  // Override body
  //
  body {
    font-family: $body-font-family;
    font-weight: 500;
  }

  //
  // Text, Paragraphs
  //
  strong {
    font-weight: Bold;
  }

  .c-text {
    font-family: $body-font-family;
  }

  //
  // font family helper classes
  //

  // Light
  .font-lt {
    font-weight: 300;
  }

  // Roman
  .font-rm {
    font-weight: 400;
  }

  // Bold
  .font-bd {
    font-weight: 700;
  }
}
