.header {
	position: relative;
	padding-top: rem-calc(10);
	margin-bottom: rem-calc(48);
	font-weight: bold;

	.top-bar {
		display: flex;
		align-items: center;

		.nav > nav > .menu {
			@include breakpoint(large up) {
				height: rem-calc(50);
			}

			> li {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 0.5rem 1rem;
				border-top: rem-calc(1) solid $white;

				@include breakpoint(large up) {
					padding: 0;
					border-top: none;
					height: 100%;
				}

				> a {
					width: 100%;
					color: $white;
					font-size: 1.125rem;

					@include breakpoint(large up) {
						width: auto;
						height: 100%;
						padding-top: 0;
						padding-bottom: 0;
						display: flex;
						align-items: center;
						transition: $global-transition;

						&:after {
							transition: $global-transition;
						}

						&:hover {
							color: $black;

							&:after {
								border-color: $black transparent transparent;
							}
						}
					}
				}

				> ul {
					width: 100%;

					> li > a {
						color: $white;
					}
				}
			}
		}

		> .nav {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			z-index: 100;

			@include breakpoint(large up) {
				position: relative;
				top: inherit;
				left: inherit;
				width: auto;
				padding-right: 0;
			}
		}
	}

	.mobile-nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: rem-calc(40);

		@include breakpoint(medium up) {
			height: rem-calc(64);
		}

		@include breakpoint(large up) {
			height: rem-calc(50);
		}
	}

	.c-logo {
		img {
			width: 100%;
			max-height: 100%;

			@include breakpoint(small up) {
				width: auto;
				max-height: rem-calc(124);
			}

			@include breakpoint(medium up) {
				max-height: 7rem;
			}
		}
	}

	.cell:last-child {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include breakpoint(large up) {
			justify-content: space-between;
		}
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		background: $medium-gray;
		height: rem-calc(40);
		z-index: -1;

		@include breakpoint(medium up) {
			height: rem-calc(64);
		}

		@include breakpoint(large up) {
			height: rem-calc(50);
		}
	}

	.title-bar {
		padding: 0;


		nav {
			width: 100%;
			padding: 0 1rem;

			.menu {
				flex-wrap: nowrap;
				width: 100%;

				> li {
					display: flex;
					align-items: center;
					justify-content: center;

					> a {
						font-size: 1.1rem;
						color: $medium-gray;
						transition: $global-transition;

						&:hover {
							color: $black;
						}
					}

					@include breakpoint(large up) {
						justify-content: flex-start;
					}
				}
			}

			@include breakpoint(large up) {
				width: auto;
				padding: 0;
				height: 50%;
			}
		}
	}

	.head-nav {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		top: 100%;
		left: 0;
		width: 100%;
		z-index: 100;
		background-color: $white;

		@include breakpoint(large up) {
			position: relative;
			top: inherit;
			left: inherit;
			width: auto;
			height: 100%;
			padding-right: 0;
			background-color: transparent;
		}
	}
}