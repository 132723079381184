.stage-image, .teaser-fullwidth {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background: rgba($black, 0.4);
	}

	.stage-content, .teaser-content {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		width: 100%;
		padding-top: 2rem;
		z-index: 1;

		@include breakpoint(large up) {
			height: 100%;
			padding-top: 0;
		}

		p, h1, h2, h3, .stage-link, .teaser-link, a {
			text-align: center;
			color: $white;
		}

		h1, h2, h3 {
			text-shadow: 0 0 .5rem rgba(0, 0, 0, .5);
			margin-bottom: 2rem;
		}

		p {
			font-size: 1.3rem;
			font-weight: bold;
			margin-bottom: 2rem;
			display: none;

			@include breakpoint(large up) {
				display: block;
			}
		}
	}

	.stage-content-mobile, .teaser-content-mobile {
		background-color: $light-gray;
		padding: 1rem 0;
		position: relative;
		z-index: 2;

		@include breakpoint(large up) {
			display: none;
		}

		p {
			color: $black;
			font-weight: bold;
		}
	}
}

.teaser-fullwidth {
	.teaser-content-mobile {
		padding-left: 1.25rem;
		padding-right: 1.25rem;

		@include breakpoint(medium up) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.grid-container.fluid > div > div > .teaser-fullwidth {
	margin: 0 rem-calc(-8);

	@include breakpoint(medium up) {
		margin: 0 rem-calc(-16);
	}
}