.c-stage-slider {
	position: relative;

	&__slides {
	}

	&__item {
		position: relative;
	}

	&__picture {

	}

	&__text {
		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		width: 100%;
		max-width: $global-width;
		margin: 0 auto;
	}

	&__hgroup {
		width: 100%;
	}

	&__header {
		padding: rem-calc(24 24 8 24);
	}

	&__subheader {

	}

	&__cta {
		margin: 1.5rem;
		bottom: 0;
	}

	&__link {

	}

	&__dots {
		position: absolute;
		bottom: rem-calc(15);
		left: 50%;
		transform: translateX(-50%);
	}

	&__arrow {
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;
		width: rem-calc(40);
		height: rem-calc(40);
		text-indent: -9999px;
		overflow: hidden;
		display: block;
		border-bottom: rem-calc(2) solid #fff;
		border-left: rem-calc(2) solid #fff;
	}
}

.c-stage-slider__header,
.c-stage-slider__subheader {
	&--white {
		color: $white;
	}
	&--black {
		color: $black;
	}
}

.c-stage-slider__arrow {
	&--prevArrow {
		left: rem-calc(20);
		transform: rotate(45deg);
	}

	&--nextArrow {
		right: rem-calc(20);
		transform: rotate(-135deg);
	}
}
