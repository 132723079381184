// Fieldsets

fieldset {
  margin-bottom: rem-calc(20px);

  legend {
    font-weight: $global-weight-bold;
  }
}

// Textareas

textarea {
  min-height: rem-calc(250px);
}

// Checkboxes and Radios

.inputs-list {
  input[type="checkbox"],
  input[type="radio"] {
    margin-bottom: 0;
  }
}

.inputs-list {
  .form-check:last-child {
    input[type="checkbox"],
    input[type="radio"] {
      margin-bottom: $form-spacing;
    }
  }
}

.form-check-label {
  display: flex;
  align-items: start;

  input {
    margin-top: 0.25rem;
  }

  span {
    line-height: $base-line-height;
  }
}

// Uploads

input[type="file"] {
  font-size: $form-label-font-size;
}

// Action

.actions {
  .button {
    margin: 1rem 0;

    &:hover {
      background-color: $primary-color;
    }
  }
}

// reCAPTCHA

.recaptcha {
  margin: 1.5rem 0;
}

