table {
	display: block;
	width: 100%;
	overflow-x: auto;

	@include breakpoint(large) {
		display: table;
		overflow-x: visible;
	}

	thead, tr.special {
		background-color: transparent;
		border-bottom: 3px solid $primary-color;
	}

	tr {
		border-bottom: 1px solid $light-gray;

		th {
			font-weight: 500;
		}
	}
}