.alternative-list {
	list-style: none;
	width: 100%;
	margin-left: 0;

	li {
		width: 100%;
		padding: 0.5rem 0;
		border-bottom: 1px solid $light-gray;

		&:first-child {
			margin-bottom: 0.5rem;
			padding-bottom: 0;
			border-bottom: 3px solid $primary-color;
		}
	}
}