.accordion {
	.accordion-item {
		border-bottom: 2px solid $light-gray;

		.accordion-title {
			display: flex;
			justify-content: space-between;
			align-items: center;

			span {
				font-weight: bold;
			}

			.arrow-accordion {
				transition: $global-transition;
				min-width: rem-calc(24);
			}
		}

		.accordion-content {
			border-top: 2px solid $light-gray;
		}

		&.is-active {
			.accordion-title > .arrow-accordion {
				transform: rotate(180deg);
			}
		}
	}
}

.tabs {
	display: flex;
	justify-content: flex-start;

	.tabs-title {
		width: 100%;
		margin: 0 0.5rem;
		border: 1px solid $light-gray;
		background-color: rgba($primary-color, 0.4);
		font-weight: bold;
		text-align: center;

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
		}

		img {
			display: none;
		}
	}
	&:after, &:before {
		display: none;
	}
}

.tabs-content, .accordion-content {
	width: 100%;

	.img {
		img {
			margin-bottom: 4rem;

			@include breakpoint(large up) {
				margin-bottom: 0;
			}
		}
	}

	&.no-space,
	.tabs-panel.no-space {
		padding: 0 0 1rem 0;
	}
}

.accordion-type2 {
	width: 100%;

	&.tabs {
		width: calc(100% + 1rem);
		margin: 0 -0.5rem;
	}

	> li.tabs-title {
		background-color: $primary-color;

		> a {
			text-align: left;
			display: block;
			padding: 0.8rem 1rem;
			transition: $global-transition;

			> div {
				color: $white;
			}

			> div > h3 {
				margin-bottom: 0;

				> p {
					line-height: 1.3;
				}
			}

			&:hover {
				> div {
					color: $primary-color;
				}
			}
		}

		&.is-active {
			> a > div {
				color: $primary-color;
			}
		}
	}

	> a {
		display: none;
	}
}

.container-tabs {
	margin-bottom: 4rem;
}