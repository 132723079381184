.background-grey {
	background-color: $light-gray;
}

.background-white {
	background-color: transparent;
}

.background-green, .background-primary {
	background-color: $primary-color;

	.frame-type-header {
		header {
			color: $white;
		}
	}
}

.spacing-small {
	margin: 1rem 0;
}

.spacing-medium {
	margin: 3rem 0;
}

.spacing-large {
	margin: 5rem 0;
}

.context {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left:calc(50% - 40px);
		transform: rotate(45deg);
		width: rem-calc(80);
		height: rem-calc(80);
	}

	&.arrow-white {
		&:after {
			background-color: $white;
		}
	}
	&.arrow-grey {
		&:after {
			background-color: $light-gray;
		}
	}
	&.arrow-primary {
		&:after {
			background-color: $primary-color;
		}
	}

	&.arrow-top {
		padding-top: rem-calc(80);

		&:after {
			top: rem-calc(-40);
		}
	}
	&.arrow-bottom {
		padding-bottom: rem-calc(80);

		&:after {
			bottom: rem-calc(-40);
		}
	}
	&.arrow-none {
		&:after {
			display: none;
		}
	}
}