@font-face {
	font-family: 'Meta';
	src: url('../Fonts/MetaWebPro-Normal.eot') format('eot');
	src: url('../Fonts/MetaWebPro-Normal.eot') format('eot'),
	url('../Fonts/MetaWebPro-Normal.woff') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Meta';
	src: url('../Fonts/MetaWebPro-Bold.eot') format('eot');
	src: url('../Fonts/MetaWebPro-Bold.eot') format('eot'),
	url('../Fonts/MetaWebPro-Bold.woff') format('woff2');
	font-weight: Bold;
	font-style: normal;
}
