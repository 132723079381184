.c-testimonial {

	&__headline {
	}
	&__subheadline {
	}
	&__body {

	}
	&__info{

	}
	&__quote {

		&::before {
			content: "“";
			font-size: 2rem;
			color: lighten($black, 80%);
			position: relative;
			top: 0;

		}

		&::after {
			content: "”";
			font-size: 2rem;
			color: lighten($black, 80%);
			position: relative;
			bottom: -1.5rem;
			line-height: 1rem;
		}

	}
	&__image {
		border-radius: 50%;
		margin-left: 50%;
		transform: translateX(-50%);
		width: auto;
	}
}

