.teaser {
	height: 100%;
	background-color: $white;

	.section {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1rem;
		transition: $global-transition;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0;
			background-color: white;
			transition: $global-transition 0.02s;
		}

		.link {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: bold;
			transition: $global-transition;

			> img.arrow-right {
				transform: rotate(-90deg);
			}
		}
	}
	@include breakpoint(large up) {
		&:hover {
			.section {
				padding-top: 0;
				padding-bottom: 2rem;

				.link {
					color: $dark-gray;
				}

				&:after {
					height: 2.01rem;
					top: -2rem;
				}
			}
		}
	}
}
