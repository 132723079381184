@import "../Patches/foundation.reveal";

// Include everything
@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
//@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
//@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

// Overrides

.grid-container {
	width: 100%;
}

.grid-container.narrow {
	max-width: $grid-row-width * 2 / 3;
}

.grid-container .grid-container {
	padding: 0;
}

.bel-home .c-stage-slider,
.bel-come .c-stage-image,
.bel-home .c-stage-text {
	margin-bottom: 1rem;
}
