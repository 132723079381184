.ce-uploads {
  margin-left: 0;

  li.media-object {
    border-bottom: 1px solid $light-gray;
    padding-bottom: $global-margin;
  }

  svg.icon {
    width: rem-calc(25);
    height: rem-calc(25);
    margin-top: rem-calc(5);
    fill: $primary-color;
  }

  p {
    margin-bottom: rem-calc(10);
  }
}