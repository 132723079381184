.benefits {

	.benefit-item {
		padding-top: 4rem;
		padding-bottom: 4rem;

		&:first-child {
			color: $white;
		}

		&:last-child {

		}

		.img {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-bottom: 2rem;
		}

		h3 {
			margin-bottom: 0;
		}

		h2 {
			margin-bottom: 2rem;
		}
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 50%;
		z-index: -1;

		@include breakpoint(large up) {
			height: 100%;
			width: 50%;
		}
	}

	&:before {
		top: 0;
		left: 0;
		background-color: $primary-color;
	}

	&:after {
		bottom: 0;
		right: 0;
		background-color: $light-gray;
	}

}