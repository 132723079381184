.contactperson {

	.name, .country {
		@include breakpoint(large up) {
			display: flex;
			justify-content: center;
		}
	}

	.name {
		p {
			display: flex;
			flex-direction: column;

			@include breakpoint(large up) {
				display: block;

				> span {
					margin-left: 0.5rem;
				}
			}
		}
	}

	.country {
		margin-bottom: 2rem;

		> img {
			margin: 0 0.5rem;
		}
	}

	.cell {
		margin-bottom: 2rem;
	}

	.contact-list {
		display: flex;
		flex-direction: column;
		justify-content: center;

		> div {
			margin-bottom: 1rem;

			> img {
				margin-right: 0.5rem;
			}
		}
	}
}