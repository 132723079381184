body {
	overflow-x: hidden;
}

.container {
	@include flex;
	width: 100%;
	max-width: $global-width;
	margin: auto;
}

.phone {
	max-width: rem-calc(60);

	a {
		display: flex;
		align-items: center;
		padding: 0.7rem;

		> img {
			height: 1.75rem;
			width: auto;
			max-width: none;
		}
		> span {
			display: none;

			@include breakpoint(large) {
				display: block;
			}
		}

		@include breakpoint(large) {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	@include breakpoint(large) {
		max-width: none;
	}
}

.button {
	font-weight: bold;
}

.button.arrow {
	position: relative;
	min-width: 15rem;
	margin-bottom: .3rem;
	padding: 1rem calc(.8rem + 50px) .8rem .8rem;
	border: none;
	overflow: hidden;
	text-transform: uppercase;

	&:hover {
		padding: 1rem calc(.8rem + 25px) .8rem calc(.8rem + 25px);
		color: $white;

		span {
			width: 100%;

			&:after {
				right: rem-calc(-50);
			}
		}
	}

	span {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: rem-calc(50);
		background-color: rgba(0,0,0,.1);
		transition: $global-transition;

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			height: 100%;
			width: rem-calc(50);
			background: transparent url(/typo3conf/ext/site/Resources/Public/Images/arrow.svg) no-repeat center;
			transition: $global-transition;
		}
	}
}