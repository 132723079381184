.cta, .fact {
	.img {
		width: 100%;
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;
	}

	.cta-item, .fact-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 2rem;
	}

	h2.fact-header {
		font-size: 4rem;
		color: $primary-color;
		text-align: center;
	}

	p {
		text-align: center;
		width: 100%;
		font-weight: bold;
	}
}