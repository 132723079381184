#toTop {
	position: fixed;
	bottom: 4rem;
	left: 1rem;
	height: 2rem;
	width: 2rem;
	background-color: $primary-color;
	padding: 1.55rem;
	min-width: 0;
	margin: 0;
	border-radius: 4rem;
	z-index: $zindex-top-button;

	&.bottom {
		bottom: 0;
	}

	@include breakpoint(medium) {
		bottom: 1rem;
	}

	span:after {
		transform: rotate(-90deg);
	}

	&:hover {
		background-color: $black;
		span:after {
			right: 0;
		}
	}
}
