.c-productlist {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .c-product,
    .c-product > a {
        height: 100%;
        width: 100%;
        display: flex;
    }

    .c-product > a {
        transition: border-bottom-color, .25s;

        &:hover {
            h4 {
                color: $primary-color;
            }
        }
    }

    .c-product {
        border-bottom: 2px solid $brown;
        padding-top: 1rem;

        width: 100%;

        @include breakpoint(large) {
            width: calc(50% - 0.5rem);
        }

        .img {
            position: relative;
            flex: 0 0 auto;
            width: percentage(1/3);
            max-width: rem-calc(200px);

            @include breakpoint(medium) {
                width: percentage(2/5);
            }

            @include breakpoint(large) {
                width: percentage(1/5);
            }

            img {
                margin-bottom: 0;
            }

            > span {
                position: absolute;
                right: rem-calc(10);
                bottom: rem-calc(10);
                display: block;
                width: rem-calc(40);
                height: rem-calc(40);
                text-align: center;
                background: $hint-color;

                > span {
                    display: block;
                    font-size: rem-calc(30px);
                    line-height: rem-calc(40px);
                    color: $body-font-color;
                    transform: perspective(#{rem-calc(100px)}) rotateY(45deg);
                }

                @extend .font-bd;
            }
        }

        h4 {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 1rem;
            margin-bottom: 0;
            background-color: $light-gray;
            color: $black;
            font-weight: $global-weight-normal;
            word-break: break-word;
            transition: color, .25s;
        }
    }
}