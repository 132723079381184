.footer {
	background-color: $dark-gray;
	padding: 2rem 0;
	border-bottom: rem-calc(15) solid $primary-color;
	font-weight: bold;

	.footer-info {
		padding: rem-calc(13) 0;
		margin: 1rem 0 0;
		border-top: rem-calc(1) solid $medium-gray;
		border-bottom: rem-calc(1) solid $medium-gray;

		p {
			color: $white;
			font-size: 1.125rem;
			margin-bottom: 0;

			@include breakpoint(large up) {
				line-height: 1.125rem;
			}

			@include breakpoint(medium down) {
				text-align: center;
			}
		}

		@include breakpoint(large up) {
			margin-bottom: 2rem;
		}

		.footer-phone {
			a {
				color: $white;
			}
		}
	}

	.navmisc {
		.menu {
			flex-direction: column;

			li {
				border-bottom: rem-calc(1) solid $medium-gray;
				width: 100%;
				text-align: center;

				a {
					font-size: 1.125rem;
				}

				@include breakpoint(large up) {
					border-bottom: none;
					width: auto;
					text-align: left;
				}

				&:first-child {
					a {
						@include breakpoint(large up) {
							padding-left: 0;
						}
					}
				}
			}

			@include breakpoint(large up) {
				flex-direction: row;
			}
		}
	}
}
