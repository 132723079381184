@import "../../../../../../../../node_modules/slick-carousel/slick/slick.scss";

ul.slick-dots {
	list-style-type: none;
	margin: 0;

	& > li {
		display: inline-block;

		&:not(:last-child) {
			margin-right: rem-calc(15);
		}

		button {
			background-color: #fff;
			text-indent: -9999px;
			width: rem-calc(15);
			height: rem-calc(15);
			border-radius: 50%;
			overflow: hidden;
			cursor: pointer;
			opacity: .75;

			&:hover {
				opacity: 1;
			}
		}
	}

	& > li.slick-active button {
		opacity: 1;
	}
}
